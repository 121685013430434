body{
    background-color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    @media only screen and (max-width: 768px) {
        margin: 0px;
    }
    .container{
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;
        main{
            padding-top: 80px;
            @media only screen and (max-width: 768px) {
                padding-top: 90px;
            }
            h1{
                color: #FFFFFF;
                text-align: center;
                padding: 30px 0px;
                font-size: 30px;
                margin: 0;
                @media only screen and (max-width: 768px) {
                    font-size: 20px;
                }
            }
            h2{
                text-align: center;
                color: #FFFFFF;
                @media only screen and (max-width: 768px) {
                    font-size: 15px;
                }
            }
        }
    }
}